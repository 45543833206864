/* Core styles */
.btn {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  user-select: none;
  padding: 16px;
  margin: 0;
  min-height: 30px;
  min-width: 50px;
  background: white;
  color: #32302f;
  border: 2px solid #32302f;
  border-radius: 24px;
}
.btn:active {
  margin-bottom: 1px;
  border-top-width: 1px;
  transform: translateY(1px);
}
.btn:hover {
  cursor: pointer;
}
.btn:disabled {
  cursor: not-allowed;
  color: rgb(170, 170, 170);
}
.btn:disabled:active {
  margin-bottom: 0;
  border-top-width: 2px;
  transform: none;
}

.btn .icon {
  width: 14px;
  height: 14px;
  font-size: 14px;
  margin-right: 6px;
  position: relative;
  top: 2px;
}

/* Styles by button type */
.btn.btn-primary {
  background: #32302f;
  color: white;
}
.btn.btn-primary:disabled {
  background: rgb(170, 170, 170);
}

.btn.btn-secondary:disabled {
  color: rgb(170, 170, 170);
}

.btn.btn-tertiary {
  background: none;
  border-radius: 0;
  border-bottom-color: #32302f;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.btn.btn-tertiary:disabled {
  color: rgb(170, 170, 170);
  border-bottom-color: rgb(170, 170, 170);
}

.btn.btn-icon {
  background: none;
  border: none;
  padding: 4px;
  min-width: unset;
}
.btn.btn-icon .icon {
  margin: 0;
  width: 22px;
  height: 22px;
  font-size: 22px;
  color: #00afff;
}
.btn.btn-icon:disabled,
.btn.btn-icon:disabled .icon {
  color: rgb(170, 170, 170);
}
