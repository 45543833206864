.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}

@media only screen and (max-width: 481px) {
  .btn-group .btn {
    width: 100%;
  }
}
