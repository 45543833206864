.transaction-card {
  position: relative;
  display: block;
  padding: 16px 48px 16px 0;
  margin: 0;
  border-bottom: 1px solid #c8c8c8;
  font-size: 16px;
}

.transaction-card .transaction-info .icon {
  position: relative;
  top: 2px;
  margin-right: 8px;
  font-size: 16px;
  width: 16px;
  height: 16px;
}

.transaction-card .transaction-card-delete-btn {
  position: absolute;
  right: 16px;
  top: 16px;
  bottom: 16px;
}
.transaction-card .transaction-card-delete-btn .icon {
  color: red;
}
