.app-header-container {
  padding: 16px;
  background: #32302f;
  color: white;
  box-shadow: 0 0 3px 1px #114466;
}

.app-header {
  align-items: center;
}

.app-header-link {
  text-decoration: none;
}

.app-header-logo {
  width: 24px;
  height: 24px;
}

.app-header-title {
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.app-header-profile-btn {
  position: relative;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: white;
  text-decoration: none;
  z-index: 1;
}

.app-header-profile-img {
  width: 24px;
  height: 24px;
  background: none;
  border: none;
}

.app-header-profile-info {
  display: none;
  position: absolute;
  background: white;
  color: #32302f;
  border: 1px solid #a8a8a8;
  border-radius: 8px;
  padding: 16px;
  right: 0;
  top: 100%;
  min-width: 100%;
}

.app-header-profile-btn:focus .app-header-profile-info,
.app-header-profile-btn:hover .app-header-profile-info {
  display: block;
  cursor: default;
}

@media only screen and (max-width: 481px) {
  .app-header-link {
    display: none;
  }
}
