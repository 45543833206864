.icon {
  user-select: none;
}
.icon.m {
  width: 32px;
  height: 32px;
  font-size: 32px;
}
.icon.l {
  width: 40px;
  height: 40px;
  font-size: 40px;
}
